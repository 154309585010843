/* calendar.module.css */
.schedule {
    position: relative;
    display: grid;
    grid-template-columns: auto 1fr;
    min-height: 600px;
    background: white;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.timeline {
    display: flex;
    flex-direction: column;
    padding-top: calc(var(--row-height) / 2);
    background: linear-gradient(to right, white 95%, rgba(255, 255, 255, 0));
    border-right: 1px solid #eee;
}

.event {
    position: absolute;
    left: 0.5rem;
    right: 0.5rem;
    border-radius: 10px;
    padding: 0.8rem;
    cursor: pointer;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    outline: 2px solid white;
    outline-offset: -2px;
    transition: transform 0.2s;
    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.event:hover {
    transform: scale(1.02);
    z-index: 1;
}

/* Gradient color classes */
.event--1 { background: linear-gradient(135deg, #00629B 0%, #00A8E8 100%); } /* Blue Gradient */
.event--2 { background: linear-gradient(135deg, #0072CE 0%, #00BFFF 100%); } /* Light Blue Gradient */
.event--3 { background: linear-gradient(135deg, #003F87 0%, #0072CE 100%); } /* Dark Blue Gradient */
.event--4 { background: linear-gradient(135deg, #00A8E8 0%, #FFFFFF 100%); } /* White-to-Blue Gradient */
.event--5 { background: linear-gradient(135deg, #00629B 0%, #FFFFFF 100%); } /* White-to-Dark Blue Gradient */
.event--6 { background: linear-gradient(135deg, #00BFFF 0%, #0072CE 100%); } /* Reverse Blue Gradient */
.event--7 { background: linear-gradient(135deg, #0072CE 0%, #00A8E8 100%); } /* Smooth Blue Gradient */
.event--8 { background: linear-gradient(135deg, #003F87 0%, #00A8E8 100%); } /* Bold Blue Gradient */
.event--9 { background: linear-gradient(135deg, #FFFFFF 0%, #0072CE 100%); } /* White-to-Medium Blue Gradient */
.event--10 { background: linear-gradient(135deg, #00A8E8 0%, #003F87 100%); } /* Reverse Bold Blue Gradient */


.modalContent {
    background: linear-gradient(145deg, #ffffff 0%, #f8f9fa 100%);
    padding: 2rem;
    border-radius: 8px;
    max-width: 600px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Keep original styles for other elements */
.timeSlot {
    height: 50px;
    font-size: 0.8rem;
    color: #666;
    padding-right: 0.5rem;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
}

.timeSlot::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    border-bottom: 1px solid #eee;
}

.daysWrapper {
    position: relative;
    overflow-x: auto;
    overflow-y: hidden;
    background: white;
    z-index: 0;
}

.daysHeader {
    display: none; /* Visible on mobile */
    position: sticky;
    top: 0;
    background: white;
    z-index: 2;
    border-bottom: 1px solid #eee;
    white-space: nowrap;
    position: relative;
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
}

.dayTab {
    padding: 1rem;
    border: none;
    background: none;
    cursor: pointer;
    font-size: 0.9rem;
    color: #333;
    font-family: 'Montserrat';
}

.dayTab.active {
    font-weight: bold;
    color: #000000;
    border-bottom: 2px solid #000000;
}

.daysGrid {
    display: grid;
    grid-template-columns: repeat(5, minmax(350px, 1fr)); /* Ensure each column has a minimum width */
    position: relative;
    background: white;
}

.dayColumn {
    position: relative;
    min-height: calc(var(--row-height) * 18);
    padding: 0 0.5rem;
    border-left: 1px solid #eee;
}

.dayColumn:first-child {
    border-left: none; /* Remove border for the first column to align with timeline */
}

.eventTime {
    font-size: 0.9rem;
    margin-bottom: 0.25rem;
    font-weight: 500;
}

.eventTitle {
    font-size: 0.9rem;
    font-weight: bold;
    word-wrap: break-word;
}
.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modalContent {
    position: relative; /* Required for absolute positioning of .modalClose */
    background: white;
    padding: 2rem;
    border-radius: 8px;
    max-width: 600px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 2020;
  }
  
  .modalClose {
    position: absolute;
    top: 1rem; /* Distance from the top of the modal content */
    right: 1rem; /* Distance from the right edge of the modal content */
    border: none; /* Remove default button styling */
    background: none; /* Ensure no background color */
    font-size: 2rem; /* Size of the "×" symbol */
    cursor: pointer; /* Pointer cursor for better UX */
    color: #333; /* Default color */
    padding: 0; /* Remove padding */
    line-height: 1; /* Align the "×" symbol properly */
  }
  
  .modalClose:hover {
    color: #007bff; /* Change color on hover for better UX */
  }
.gridLines {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none; /* Make grid lines not interfere with events */
}

.gridLine {
    position: absolute;
    left: 0;
    right: 0;
    border-bottom: 1px solid #eee;
    width: 100%;
    min-width: 1620px;
}

@media (max-width: 1620px) {
    .schedule {
        overflow-x: scroll; /* Enable horizontal scrolling */
        overflow-y: hidden;
    }
}

@media (max-width: 1000px) {
    .daysGrid {
        grid-template-columns: 1fr;
    }

    .dayColumn:not(.active) {
        display: none;
    }

    .timeline {
        margin-top: 50px;
    }

    .daysHeader {
        display: flex;
        justify-content: space-between;
    }

    .schedule {
        overflow-x: none; /* Enable horizontal scrolling */
        overflow-y: auto;
    }

    .dayLabel {
        display: none;
    }
    .gridLine {
        width: 100%;
        min-width: 0px;
    }
}

.dayLabel {
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    padding: 0.5rem 0;
    position: sticky;
    top: 0;
    z-index: 1001;
    color: rgb(247, 247, 247);
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
}