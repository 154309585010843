/* Initially hidden state */
.hidden {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

/* Visible state */
.visible {
  opacity: 1;
  transform: translateY(0);
}

/* Main section container */
.become_tutor_section {
  padding: 6rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

/* Content container */
.content_container {
  max-width: 800px;
  width: 100%;
  text-align: center;
  z-index: 1;
}

/* Main title styling */
.main_title {
  font-size: 4rem;
  font-weight: bold;
  color: #2c6a9b;
  margin-bottom: 2rem;
  margin: 0 auto;
  text-transform: uppercase;
}

/* Subtitle styling */
.subtitle {
  font-size: 2rem;
  font-weight: bold;
  color: #344969;
  margin-bottom: 2rem;
}

/* Requirements list styling */
.requirements_list {
  list-style: none;
  padding: 0;
  margin: 0 0 3rem 0;
  font-size: 1.5rem;
  color: #333;
}

.requirement_item {
  margin-bottom: 1rem;
  align-items: center;
  font-weight: bold;
}

.checkmark {
  font-size: 1.5rem;
  color: #2c6a9b;
  margin-right: 0.75rem;
  animation: bounce 1s infinite alternate;
}

/* Apply button */
.apply_button {
  background-color: #2c6a9b;
  color: #ffffff;
  padding: 1rem 2.5rem;
  border: none;
  border-radius: 50px;
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.apply_button:hover {
  background-color: #344969;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.25);
}

/* Animations */
@keyframes bounce {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-5px);
  }
}

/* Responsive design */
@media screen and (min-width: 2400px) {
  .main_title {
    font-size: 4.01rem;
  }
  .subtitle {
    font-size: 3rem;
  }
  .requirements_list {
    font-size: 2.25rem;
  }
  .apply_button {
    font-size: 2.25rem;
    padding: 1.5rem 3.5rem;
  }
}

@media screen and (min-width: 1800px) and (max-width: 2399px) {
  .main_title {
    font-size: 4rem;
  }
  .subtitle {
    font-size: 2.5rem;
  }
  .requirements_list {
    font-size: 2rem;
  }
  .apply_button {
    font-size: 2rem;
    padding: 1.25rem 3rem;
  }
}

@media screen and (max-width: 1024px) {
  .main_title {
    font-size: 3.5rem;
  }
  .subtitle {
    font-size: 1.75rem;
  }
  .requirements_list {
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 768px) {
  .main_title {
    font-size: 3rem;
    margin-bottom: 1.5rem;
  }
  .subtitle {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  .requirements_list {
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }
  .apply_button {
    padding: 0.875rem 2rem;
    font-size: 1.25rem;
  }
}

@media screen and (max-width: 480px) {
  .main_title {
    font-size: 2.5rem;
  }
  .subtitle {
    font-size: 1.25rem;
  }
  .requirements_list {
    font-size: 1.1rem;
  }
  .apply_button {
    padding: 0.75rem 1.75rem;
    font-size: 1.125rem;
  }
}