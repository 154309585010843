/* main section container */
.whoWeAre {
  background-color: #c85b12;
  color: white;
  padding: 4.32rem; /* Increased by 8% */
  display: flex;
  align-items: center;
  justify-content: center; /* Center horizontally */
}

/* global container */
.container {
  max-width: 1800px; /* Increased by 8% */
  margin: 0 auto;
  width: 100%;
}

/* grid layout for four main sections */
.gridContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto; /* Remove vertical stretching */
  gap: 4.32rem; /* Increased by 8% */
  width: 100%;
}

/* title section */
.titleSection {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.titleContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.16rem; /* Increased by 8% */
}

/* title with underline */
.title {
  font-size: 2.7rem; /* Increased by 8% */
  font-weight: bold;
  position: relative;
  display: inline-block;
  margin-bottom: 0.54rem; /* Increased by 8% */
}

.underline {
  position: absolute;
  left: 0;
  bottom: -8.64px; /* Increased by 8% */
  width: 100%;
  height: 3.24px; /* Increased by 8% */
  background-color: white;
}

/* description text */
.description {
  font-size: 1.62rem; /* Increased by 8% */
  line-height: 1.62; /* Increased by 8% */
  max-width: 90%;
}

/* image section */
.imageSection {
  width: 100%;
}

/* Animation styles */
.hidden {
  opacity: 0;
  transform: translateY(21.6px); /* Increased by 8% */
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.visible {
  opacity: 1;
  transform: translateY(0);
}

.imageWrapper {
  width: 100%;
  height: 108%; /* Increased by 8% */
  border-radius: 8px;
  overflow: hidden;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* content section */
.contentSection {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 2.16rem; /* Increased by 8% */
}

.services {
  font-size: 1.35rem; /* Increased by 8% */
  line-height: 1.62; /* Increased by 8% */
  max-width: 90%;
}

/* "Learn More" button styling */
.learnMoreButton {
  background-color: white;
  color: #c85b12;
  text-decoration: none;
  border: none;
  border-radius: 9999px;
  padding: 0.7rem 2.16rem; /* Increased padding by 8% */
  font-size: 1.08rem; /* Increased font size by 8% */
  font-weight: 500;
  cursor: pointer;
  transition: all 1s ease;
  width: fit-content;
}

.learnMoreButton:hover {
  background-color: rgba(255, 255, 255, 0.9);
}

/* Media queries for responsiveness */
@media screen and (min-width: 2400px) {
  .learnMoreButton {
    padding: 1.08rem 2.7rem; /* Adjusted for large screens */
    font-size: 1.35rem; /* Increased by 8% */
  }
}

@media screen and (min-width: 2000px) and (max-width: 2399px) {
  .learnMoreButton {
    padding: 0.97rem 2.43rem; /* Adjusted for medium-large screens */
    font-size: 1.24rem; /* Increased by 8% */
  }
}

@media screen and (min-width: 1800px) and (max-width: 1999px) {
  .learnMoreButton {
    padding: 0.92rem 2.16rem; /* Adjusted for medium screens */
    font-size: 1.19rem; /* Increased by 8% */
  }
}

@media screen and (min-width: 1600px) and (max-width: 1799px) {
  .learnMoreButton {
    padding: 0.86rem 2.05rem; /* Adjusted for smaller medium screens */
    font-size: 1.14rem; /* Increased by 8% */
  }
}

@media screen and (min-width: 1400px) and (max-width: 1599px) {
  .learnMoreButton {
    padding: 0.81rem 1.94rem; /* Adjusted for smaller screens */
    font-size: 1.08rem; /* Increased by 8% */
  }
}

@media screen and (min-width: 1025px) and (max-width: 1399px) {
  .learnMoreButton {
    padding: 0.75rem 1.89rem; /* Adjusted for medium screens */
    font-size: 1.03rem; /* Increased by 8% */
  }
}

@media screen and (max-width: 1024px) {
  .learnMoreButton {
    padding: 0.7rem 1.84rem; /* Adjusted for tablets */
    font-size: 0.97rem; /* Increased by 8% */
  }
}

@media screen and (max-width: 768px) {
  .whoWeAre {
    padding: 2.16rem; /* Increased by 8% */
    flex-direction: column; /* Stack elements vertically on mobile */
  }

  .gridContainer {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    gap: 2.16rem; /* Increased by 8% */
  }

  .title {
    font-size: 2.16rem; /* Increased by 8% */
  }

  .description,
  .services {
    font-size: 1.08rem; /* Increased by 8% */
  }

  .imageWrapper {
    height: 324px; /* Adjusted height */
  }

  .learnMoreButton {
    padding: 0.65rem 1.72rem; /* Adjusted button size */
    font-size: 1.02rem; /* Increased by 8% */
  }
}
