.desktopContainer {
	width: 95%;
  position: fixed;
  left: 2.5%;
  top: 0;
}

.logo {
  float: left;
  padding: 10px 0;
  height: 72px;
  filter: drop-shadow(5px 0px 4px rgba(0, 0, 0, 0.225));
}

.navDesktop {
  float: right;
}

.navDesktop ul {
  margin: 0;
  padding: 0;
  list-style: none;
  align-items: center;
  display: flex;
}

.navDesktop li {
  margin-left: 2.6vw;
  padding-top: 23px;
}

.link {
  color: white;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  position: relative;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  transition: all ease-in-out 250ms;
}

.link::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  border-radius: 4px;
  background-color: white;
  bottom: 0;
  left: 0;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform .3s ease-in-out;
}

.link:hover::before {
  transform-origin: left;
  transform: scaleX(1);
}

.navDesktop .link:hover {
  opacity: 1;
}

.navDesktop li .link {
  opacity: 1;
  transition: opacity 250ms ease-in-out;
}

.navDesktop ul:hover .link:not(:hover) {
  opacity: 0.5;
}

.sign-in-btn {
  outline: none;
  cursor: pointer;
  font-size: 14px;
  line-height: 1;
  margin-left: -5%;
  border-radius: 500px;
  transition-property: background-color,border-color,color,box-shadow,filter;
  transition-duration: .3s;
  border: 1px solid transparent;
  letter-spacing: 2px;
  min-width: 160px;
  text-transform: uppercase;
  font-family: "AeonikFono", sans-serif;
  white-space: normal;
  font-weight: 700;
  text-align: center;
  padding: 16px 14px 18px;
  color: white;
  box-shadow: inset 0 0 0 2px white;
  background-color: transparent;
  height: 48px;
  filter: drop-shadow(5px 0px 6px rgba(0, 0, 0, 0.525));  
}

.id {
  background: white;
  color: black;
  mix-blend-mode: multiply;
}

.sign-in-btn:hover{
  background: white;
  color: black;
  mix-blend-mode: multiply;
}

.accountIcon {
  width: 40px;
  height: 40px;
}

.mobileLink {
  text-decoration: none;
  color: #1E1E23;
  opacity:1;
  font-size: 1.5em;
  font-weight: 400;
  transition: 200ms;
}


.mobileSignInBtn {
  outline: none;
  cursor: pointer;
  font-size: 14px;
  line-height: 1;
  margin-left: -5%;
  border-radius: 500px;
  transition-property: background-color,border-color,color,box-shadow,filter;
  transition-duration: .3s;
  border: 1px solid transparent;
  letter-spacing: 2px;
  min-width: 100%;
  text-transform: uppercase;
  white-space: normal;
  font-weight: 700;
  text-align: center;
  padding: 16px 14px 18px;
  color: black;
  box-shadow: inset 0 0 0 2px black;
  background-color: transparent;
  height: 48px;
}

.mobileLink:hover {
  opacity:0.5;
}

.mobileNav ul {
  padding: 0;
  list-style-type: none;
}

.mobileContainer {
  Display: flex;
  position: fixed;
  top: 0;
}

#menuToggle {
  display: flex;
  flex-direction: column;
  position: relative;
  top: 25px;
  left: 25px;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
}

#menuToggle input
{
  display: flex;
  width: 40px;
  height: 32px;
  position: absolute;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
}

#menuToggle span
{
  display: flex;
  width: 29px;
  height: 2px;
  margin-bottom: 5px;
  position: relative;
  background: #ffffff;
  border-radius: 3px;
  z-index: 1;
  transform-origin: 5px 0px;
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease;
              filter: drop-shadow(5px 0px 6px rgba(0, 0, 0, 0.525));  

}

#menuToggle span:first-child
{
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2)
{
  transform-origin: 0% 100%;
}

#menuToggle input:checked ~ span
{
  opacity: 1;
  transform: rotate(45deg) translate(-3px, -1px);
  background: #36383F;
}
#menuToggle input:checked ~ span:nth-last-child(3)
{
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

#menuToggle input:checked ~ span:nth-last-child(2)
{
  transform: rotate(-45deg) translate(0, -1px);
}

#menu
{
  position: absolute;
  width: 280px;
  height: 1800px;
  box-shadow: 0 0 10px #85888C;
  margin: -50px 0 0 -50px;
  padding: 50px;
  padding-top: 125px;
  background-color: #F5F6FA;
  -webkit-font-smoothing: antialiased;
  transform-origin: 0% 0%;
  transform: translate(-100%, 0);
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
}

#menu li
{
  padding: 10px 0;
  transition-delay: 2s;
}

#menuToggle input:checked ~ ul
{
  transform: none;
}

@media screen and (max-width: 930px) {
  header .desktopContainer {
    display: none;
  }

  header .mobileContainer {
    display: block;
  }
}

@media screen and (min-width: 930px) {
  header .desktopContainer {
    display: block;
  }

  header .mobileContainer {
    display: none;
  }
}

.mobileLogo {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  right: 3%;
  position: fixed;
  text-decoration: none;
  gap: 20px;
  height: 10px;
  margin-top: -12px;
  filter: drop-shadow(5px 0px 6px rgba(0, 0, 0, 0.525));  

}

.mobileLogoText {
  color: white;
  text-decoration: none;
  font-size: 28px;
  font-weight: 800;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);


  position: relative;

  transition: all ease-in-out 250ms;
}

.mobileLogoImg {
  float: left;
  height: 48px;
}