.Container {
  display: flex;
  flex-direction: row;
  align-items: center; /* Center the sections vertically */
  justify-content: center; /* Center the sections horizontally */
  background-color: white;
  color: black;
  text-align: center;
  height: max-content;
  width: 100%;
  z-index: 100;
  padding-bottom: 40px;
}

.SponsorSection {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align items */
  justify-content: center;
  width: 80%; /* Ensure Sponsor section and Instagram section have equal width */
}

.SectionDescription {
  font-size: 2rem;
  margin-top: 3rem;
}

.Client-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 items per row by default */
  gap: 20px; /* Adds space between items */
  width: 100%;
  align-items: center;
  justify-items: center;
}

.Client-item {
  width: 80%;
  max-width: 350px; /* Ensures it doesn't get too large */
  flex: 1 auto;
  transition: all 0.5s;
  display: flex;
  justify-content: center; /* Center the image horizontally */
  align-items: center; /* Center the image vertically */
  margin: auto; /* Center the image within the container */
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .Client-list {
    grid-template-columns: repeat(2, 1fr); /* Always 2 items per row on smaller screens */
  }
}

.Client-item {
  max-width: 300px; /* Optional: Adjust size for smaller screens */
}
