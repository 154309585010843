/* Location section */
.location_section {
  padding-top: 4rem;
  background-color: #f8f9fa;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.maps_container {
  width: 80%;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  gap: 2rem;
}

.map_image {
  width: 58%;
}

.location_text {
  text-align: center;
  max-width: 800px;
  margin: 2rem auto;
  padding: 0 2rem;
  font-size: 2rem;
  line-height: 1.6;
  color: #333;
  font-weight: bold;
}

/* Responsive design */
@media screen and (min-width: 2400px) {
  .maps_container {
    max-width: 2400px;
    width: 70%;
  }

  .location_text {
    max-width: 1600px;
    font-size: 2.5rem;
  }
}

@media screen and (min-width: 1800px) and (max-width: 2399px) {
  .maps_container {
    max-width: 2000px;
    width: 75%;
  }

  .location_text {
    max-width: 1400px;
    font-size: 2.25rem;
  }
}

@media screen and (max-width: 1024px) {
  .maps_container {
    gap: 1.5rem;
  }

  .location_text {
    font-size: 1.75rem;
    padding: 0 1.5rem;
  }
}

@media screen and (max-width: 768px) {
  .maps_container {
    flex-direction: column;
    align-items: center;
    width: 95%;
  }

  .map_image {
    width: 100%;
    max-width: 500px;
  }

  .location_text {
    font-size: 1.5rem;
    padding: 0 1rem;
  }
}

@media screen and (max-width: 480px) {
  .location_text {
    font-size: 1.25rem;
  }
}
