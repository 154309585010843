/* General Styles */
.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6rem;
  min-height: 100vh;
}

.description {
  font-size: 1.2rem;
  text-align: left; /* Align text to the left */
  width: 60%; /* Adjusted width for better padding */
  padding: 0 2rem; /* Added padding around the description */
  margin: 0 auto;
}

.group__container {
  width: 100%;
  height: 40vw;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -1;
}

.group__container .group__img {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.group__img {
  filter: brightness(0.7);
}

.image {
  display: flex;
  width: 100%;
}

.fade-in-section {
  opacity: 0;
  transform: translateY(10vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 1.2s ease-out;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;
}

.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.mainContainer {
  padding: 2rem;
}

.image__container {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.fade__in {
  opacity: 1;
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align info content to the left */
  padding: 1rem 0; /* Added padding around the info */
}

.info__title {
  font-weight: bold;
  text-align: left; /* Align text to the left */
  padding-bottom: 0.5rem; /* Added padding below the title */
}

.profile__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align profile content to the left */
  margin-bottom: 5vh;
  padding: 1rem 0; /* Added padding around the profile container */
}

.name {
  font-size: large;
  text-align: left; /* Align text to the left */
  margin-bottom: 0; /* Removed margin below the name */
}

.none {
  display: none;
}

/* Overlay Styles */
.overlay__title {
  display: flex;
  font-size: 5rem;
  color: #ffffff;
  margin-top: 15%;
  justify-content: center;
  text-align: left; /* Align text to the left */
}

.overlay {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  z-index: 12;
  font-size: 2.5rem;
  background: rgba(54, 53, 53, 0.6);
  width: 100%;
  height: 100%;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .main {
    padding: 2rem;
  }

  .title {
    font-size: 1.75rem; /* Smaller font size for smaller screens */
    text-align: center; /* Center the text on smaller screens */
  }

  .description {
    font-size: 1.5rem; /* Larger font size for smaller screens */
    width: 80%; /* Adjusted width for smaller screens */
    padding: 0 1rem; /* Adjusted padding for smaller screens */
  }

  .image__container {
    margin-top: 1rem;
  }

  .info {
    margin-top: 1rem;
    padding: 1rem; /* Added padding for smaller screens */
  }

  .info__title {
    font-size: 1.75rem; /* Larger font size for smaller screens */
    padding-bottom: 0.5rem; /* Added padding below the title */
  }

  .name {
    font-size: 1.5rem; /* Larger font size for smaller screens */
    margin-bottom: 0; /* Removed margin below the name */
  }

  .profile__container {
    padding: 1rem; /* Added padding for smaller screens */
  }
}

@media screen and (max-width: 600px) {
  .overlay__title {
    font-size: 3rem; /* Larger font size for smaller screens */
  }
}

/* Mobile Specific Styles */
.group__content {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100%;
}

.group__header {
  text-align: center; /* Center the text */
  color: white;
  font-size: 5rem;
  font-weight: bold;
  z-index: 10;
}

.group__title {
  font-size: 6vw;
  text-align: center; /* Center the text */
}

.group__subtitle {
  text-align: center; /* Center the text */
}

.group__img {
  width: 100%;
}

.member__grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 16px;
  gap: 12px; /* Reduced gap between grid items */
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background-color: white;
}

@media screen and (max-width: 1000px) {
  .group__header {
    font-size: 2.2rem;
    padding-bottom: 20px;
  }

  .member__grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 600px) {
  .member__grid {
    grid-template-columns: 1fr;
  }
}

/* Member Styles */
.member__container {
  width: 100%;
  max-width: 500px; /* Further increased maximum width */
  padding: 32px; /* Further increased padding around the member container */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start; /* Align member content to the left */
}

.outer_image {
  margin-bottom: 0rem;
}

.image_wrapper img {
  aspect-ratio: 3 / 4;
  object-fit: cover;
  width: 100%;
  height: auto;
}

.member__info {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align member info to the left */
}

.member__name {
  color: #2C6A9B;
  font-weight: bold;
  font-size: 4vh; /* Increased font size */
  text-align: left; /* Align text to the left */
  margin-bottom: 0.25rem; /* Added small margin below the name */
}

.member__title {
  font-weight: bold;
  margin: 1; /* Removed gap between name and title */
  font-size: 3vh; /* Increased font size */
  text-align: left; /* Align text to the left */
}

.member__links {
  display: flex;
  gap: 12px; /* Reduced gap between links */
  justify-content: flex-start; /* Align links to the left */
}

.linkedin_icon,
.email_icon {
  transition: all 0.15s ease-in-out;
  font-size: 3rem;
  cursor: pointer;
  color: black; /* Set color to black */
  text-decoration: none; /* Remove underline decoration */
}

.linkedin_icon:hover {
  fill: orange;
}

.email_icon:hover {
  fill: blue;
}


/* Responsive design */
@media screen and (min-width: 2400px) {
  .group__header {
    font-size: 7rem;
  }
}

@media screen and (min-width: 1800px) and (max-width: 2399px) {
  .group__header {
    font-size: 6rem;
  }
}

@media screen and (max-width: 1024px) {
  .group__header {
    font-size: 3.5rem;
  }
}

@media screen and (max-width: 768px) {
  .group__header {
    font-size: 3rem;
  }
}

@media screen and (max-width: 480px) {
  .group__header {
    font-size: 2rem;
  }
}
