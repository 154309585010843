/* Main container */
.container {
  width: 100%;
  min-height: 100vh;
  background-color: white;
}

/* Hero section */
.hero_container {
  width: 100%;
  height: 40vw;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
}

.hero_content {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.hero_header {
  text-align: center;
  color: white;
  font-size: 5rem;
  font-weight: bold;
  z-index: 10;
}

.hero_img {
  width: 100%;
  filter: brightness(0.7);
}

/* Header section */
.title_container {
  width: 100%;
  background-color: #2c6a9b;
  padding: 4rem 0;
}

.title {
  font-weight: bold;
  color: white;
  font-size: 5rem;
  text-align: center;
  margin: 0;
}

/* Calendar section */
.calendar_section {
  padding-top: 4rem;
  background-color: #2c6a9b;
}

.calendar_title {
  color: white;
  font-size: 3rem;
  text-align: center;
  margin-bottom: 2rem;
  margin-top: 0rem;
}

/* Calendar section */
.calendar_section {
  padding: 4rem;
  background-color: #2c6a9b;
}

.calendar_title {
  color: white;
  font-size: 5rem;
  text-align: center;
  margin-bottom: 4rem;
}

/* Responsive design */
@media screen and (min-width: 2400px) {
  .hero_header {
    font-size: 7rem;
  }

  .title {
    font-size: 7rem;
  }

  .calendar_title {
    font-size: 7rem;
  }
}

@media screen and (min-width: 1800px) and (max-width: 2399px) {
  .hero_header {
    font-size: 6rem;
  }

  .title {
    font-size: 6rem;
  }

  .calendar_title {
    font-size: 6rem;
  }
}

@media screen and (max-width: 1024px) {
  .hero_header {
    font-size: 3.5rem;
  }

  .title {
    font-size: 4rem;
    padding: 0 1rem;
  }

  .calendar_title {
    font-size: 4rem;
    padding: 0 1rem;
  }
}

@media screen and (max-width: 768px) {
  .hero_container {
    height: 60vw; /* Taller container for mobile */
  }

  .hero_header {
    font-size: 3rem;
  }

  .title {
    font-size: 3rem;
  }

  .calendar_title {
    font-size: 3rem;
  }

  .calendar_section {
    padding: 2rem 1rem;
  }
}

@media screen and (max-width: 480px) {
  .hero_header {
    font-size: 2rem;
  }

  .title {
    font-size: 2.5rem;
  }

  .calendar_title {
    font-size: 2.5rem;
  }
}
