.footer {
  padding: 30px 0px;
  text-align: center;
  bottom: 0;
  width: 80%;
  color: white;
  background-color: #c85b12;
  margin: 0 auto;
}

.copyright {
  color: white;
  font-size: 0.8em;
  margin-top: 20px;
}

.news-letter-input {
  width: 15vh;
  height: 36px;
  border-radius: 7px;
  padding-left: 12px;
  font-size: 12px;
  font-weight: normal;
  transition: border-color 150ms ease-in-out 0s;
  background-color: rgb(255, 255, 255);
  outline: none;
  border-style: none;
  font-family: "AeonikFono", sans-serif;
}

.news-letter {
  display: flex;
  gap: 20px;
  justify-content: center;
}

/* CSS */
.subscribe-button {
  align-items: center;
  background-color: #2c6a9b;
  border: 0;
  border-radius: 7px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-flex;
  font-size: 14px;
  font-weight: 900;
  justify-content: center;
  line-height: 20px;
  max-width: 480px;
  min-height: 40px;
  min-width: 0px;
  overflow: hidden;
  padding: 0px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  touch-action: manipulation;
  transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s,
    box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s,
    color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  font-family: "AeonikFono", sans-serif;
  transition: all 500ms;
}

.subscribe-button:hover,
.subscribe-button:focus {
  background-color: #205177;
  color: #ffffff;
}

.subscribe-button:active {
  background: #09223b;
  color: rgb(255, 255, 255, 0.7);
}

.subscribe-button:disabled {
  cursor: not-allowed;
  background: rgba(0, 0, 0, 0.08);
  color: rgba(0, 0, 0, 0.3);
}

.ul {
  list-style-type: none;
  display: flex;
  justify-content: center;
  padding: 0;
  gap: 20px;
}

.socialLogos {
  width: 36px;
}

.join {
  padding-left: 20px;
  padding-right: 20px;
}