/* Courses section styles */
.courses_section {
  padding: 4rem 0;
  background-color: #f8f9fa;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.courses_header {
  width: 100%;
  text-align: center;
  margin-bottom: 3rem;
  font-weight: bold;
}

.courses_title {
  font-size: 5rem;
  margin-bottom: 1rem;
  color: #000;
  font-weight: bold;
}

.courses_subtitle {
  font-size: 2rem;
  font-weight: bold;
  color: #000;
  margin-bottom: 1rem;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #e0e0e0;
  margin-top: 1rem;
}

.courses_list {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: center;
}

/* Course card styling */
.course_card {
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 100%;
  max-width: 1200px;
}

.course_preview {
  min-height: 80px;
  padding: 1.5rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  cursor: pointer;
}

.course_main_info {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex: 1;
}

.status_indicator {
  width: 24px;
  height: 24px;
  background-color: #90ee90;
  border-radius: 50%;
  flex-shrink: 0;
}

.course_name {
  font-weight: bold;
  font-size: 1.5rem;
  color: black;
  margin: 0;
}

.expand_button {
  background: none;
  border: none;
  padding: 0.5rem;
  color: #666;
  font-size: 1.2rem;
  transition: transform 0.2s ease;
  cursor: pointer;
  margin-left: 1rem;
}

.expand_button.expanded {
  transform: rotate(180deg);
}

/* Course details styling */
.course_details {
  padding: 2rem;
  border-top: 1px solid #e0e0e0;
  background: #f8f9fa;
  justify-content: center;
  box-shadow: inset 0 4px 8px -4px rgba(0, 0, 0, 0.1);
}

/* Grid layout for tutors */
.tutors_grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
}

.tutor_card {
  background: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.2s ease-in-out;
}

.tutor_card:hover {
  transform: scale(1.02);
}

.tutor_card strong {
  display: block;
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
  color: #333;
}

.tutor_card div {
  font-size: 0.9rem;
  color: #666;
}

/* Responsive design */
@media screen and (max-width: 1024px) {
  .courses_title {
    font-size: 4rem;
  }
  .courses_subtitle {
    font-size: 1.75rem;
    padding: 0 1rem;
  }
  .course_preview {
    padding: 1.25rem 1.5rem;
  }
}

@media screen and (max-width: 768px) {
  .courses_section {
    padding: 2rem 0;
  }
  .courses_title {
    font-size: 3rem;
  }
  .courses_subtitle {
    font-size: 1.5rem;
  }
  .course_name {
    font-size: 1.2rem;
  }
  .tutor_card strong,
  .tutor_card div {
    font-size: 0.9rem;
  }
  .course_details {
    padding: 1.5rem;
  }
}