.alert {
  height: max-content;
  display: flex;
  align-items: center;
  width: 60%;
  left: 20%;
  top: 7%;
  position: relative;
  justify-content: center;
  padding: 10px 0%;
  font-size: 1.3vh;
  position: fixed;
  transform: translateY(-300%);
  transition: transform 0.5s ease-in-out;
}

.hidden {
  display: none;
}

@keyframes swoopDown {
  0% {
    transform: translateY(-300%);
  }
  10% {
    transform: translateY(0);
  }
  90% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-300%);
  }
}

.show {
  display: flex;
  animation: swoopDown 4s ease-in-out forwards;
}

.alert > .start-icon {
  min-width: 20px;
  text-align: center;
}

.alert > .start-icon {
  margin-right: 5px;
}

.notification-text {
    text-align: center;
}

.alert-simple.alert-success {
  border: 1px solid rgba(36, 241, 6, 0.46);
  background-color: rgba(7, 149, 66, 0.5);
  box-shadow: 0px 0px 2px #259c08;
  color: #0ad406;
  text-shadow: 2px 1px #00040a;
  transition: 0.5s;
  cursor: pointer;
  border-radius: 4px;
}
.alert-success:hover {
  background-color: rgba(7, 149, 66, 0.35);
  transition: 0.5s;
}
.alert-simple.alert-info {
  border: 1px solid rgba(6, 44, 241, 0.46);
  background-color: rgba(7, 73, 149, 0.5);
  box-shadow: 0px 0px 2px #0396ff;
  color: #0396ff;
  text-shadow: 2px 1px #00040a;
  transition: 0.5s;
  cursor: pointer;
  border-radius: 4px;
}

.alert-info:hover {
  background-color: rgba(7, 73, 149, 0.35);
  transition: 0.5s;
}

.alert-simple.alert-warning {
  border: 1px solid rgba(241, 142, 6, 0.81);
  background-color: rgba(220, 128, 1, 0.5);
  box-shadow: 0px 0px 2px #ffb103;
  color: #ffb103;
  text-shadow: 2px 1px #00040a;
  transition: 0.5s;
  cursor: pointer;
  border-radius: 4px;
}

.alert-warning:hover {
  background-color: rgba(220, 128, 1, 0.33);
  transition: 0.5s;
}

.alert-simple.alert-danger {
  border: 1px solid rgba(241, 6, 6, 0.81);
  background-color: rgba(220, 17, 1, 0.5);
  box-shadow: 0px 0px 2px #ff0303;
  color: #ff0303;
  text-shadow: 2px 1px #00040a;
  transition: 0.5s;
  cursor: pointer;
  border-radius: 4px;
}

.alert-danger:hover {
  background-color: rgba(220, 17, 1, 0.33);
  transition: 0.5s;
}

.alert-simple.alert-primary {
  border: 1px solid rgba(6, 241, 226, 0.81);
  background-color: rgba(1, 204, 220, 0.5);
  box-shadow: 0px 0px 2px #03fff5;
  color: #03d0ff;
  text-shadow: 2px 1px #00040a;
  transition: 0.5s;
  cursor: pointer;
  border-radius: 4px;
}

.alert-primary:hover {
  background-color: rgba(1, 204, 220, 0.33);
  transition: 0.5s;
}
.alert:before {
  content: "";
  position: absolute;
  width: 0;
  height: calc(100% - 44px);
  border-left: 1px solid;
  border-right: 2px solid;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  height: 20px;
}