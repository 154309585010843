:root {
  --main-bg-color: #2c6a9b;
  --main-text-color: white;
  --link-bg-color: white;
  --link-text-color: #2c6a9b;
  --underline-color: #32363d;
  --max-width: 1700px;
  --border-radius: 8px;
  --transition: all 0.3s ease;
}

.whatWeDo {
  background-color: var(--main-bg-color);
  color: var(--main-text-color);
  display: flex;
  justify-content: center;
  padding: 4rem;
}

.container {
  width: 100%;
  max-width: 1800px;
}

.titleSection {
  grid-column: span 2;
  text-align: center;
}

.title {
  font-size: clamp(2rem, 5vw, 3rem);
  font-weight: bold;
  position: relative;
  margin-bottom: 1rem;
}

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.visible {
  opacity: 1;
  transform: translateY(0);
}

.imageWrapper {
  width: 100%;
  aspect-ratio: 16 / 9;
  border-radius: var(--border-radius);
  overflow: hidden;
  margin-bottom: 1rem;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

h3 {
  font-size: clamp(1.25rem, 3vw, 2rem);
  margin-bottom: 1rem;
}

p {
  font-size: clamp(1rem, 2.5vw, 1.5rem);
  margin-bottom: 1rem;
  line-height: 1.6;
}

.link {
  background-color: var(--link-bg-color);
  color: var(--link-text-color);
  text-decoration: none;
  border-radius: 9999px;
  padding: 0.75rem 2rem;
  font-size: 1rem;
  font-weight: 500;
  transition: var(--transition);
}

.link:hover {
  background-color: rgba(255, 255, 255, 0.9);
}

/* Default Grid Layout */
.gridContainer {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns by default */
  gap: 4rem;
}

/* Tablet (max-width: 1024px) */
@media screen and (max-width: 1024px) {
  .gridContainer {
    grid-template-columns: 1fr; /* Single column layout for tablets */
    gap: 2rem;
  }

  .title {
    font-size: clamp(
      2.5rem,
      6vw,
      3.5rem
    ); /* Larger titles for better visibility */
  }

  h3 {
    font-size: clamp(1.5rem, 4vw, 2.5rem); /* Larger section titles */
  }

  p {
    font-size: clamp(1.25rem, 3vw, 1.75rem); /* Larger descriptions */
  }
}

/* Mobile (max-width: 768px) */
@media screen and (max-width: 768px) {
  .gridContainer {
    grid-template-columns: 1fr; /* Single column layout for mobile */
    gap: 1.5rem; /* Reduced gap for tighter layout on smaller screens */
  }

  .title {
    font-size: clamp(2rem, 5vw, 3rem); /* Adjusted title size */
  }

  h3 {
    font-size: clamp(1.5rem, 4vw, 2rem); /* Adjusted section titles */
  }

  p {
    font-size: clamp(1.25rem, 3vw, 1.5rem); /* Adjusted descriptions */
  }

  .link {
    font-size: 1rem;
    padding: 0.5rem 1.5rem;
  }
}
